<template>
  <div ref="windowRef" class="file">
    <!-- 主体 -->
    <div v-loading="loading" class="content" @mousedown="holdDown" @mouseup="holdUp">
      <div class="left">
        <el-tree
          ref="tree"
          :data="data"
          :load="loadNode"
          lazy
          node-key="path"
          :default-expanded-keys="defaultExpandedKeys"
          :props="defaultProps"
          icon-class="el-icon-arrow-right"
          @node-click="handleNodeClick"
          @node-contextmenu="dirsRightClick"
        >
          <template #default="{ node }">
            <span class="custom-tree-node" @mouseenter="mouseenter(node)">
              <span><svg-icon
                style="margin: 0 12px"
                icon-class="files"
              /></span>
              <span>{{ node.label }}</span>
            </span>
          </template>
        </el-tree>
      </div>
      <div class="right">
        <div class="header">
          <div>
            <el-button-group>
              <el-button
                icon="el-icon-arrow-left"
                @click="prevHandle"
              />
              <!-- <el-button
                ><i class="el-icon-arrow-right el-icon--right"></i
              ></el-button> -->
              <el-button
                style="margin: 0 10px"
                @click="refreshHandle"
              ><i class="el-icon-refresh-right" /></el-button>
            </el-button-group>
            <el-input v-model="path" disabled />
          </div>
          <div class="handle">
            <el-button-group style="display:flex">
              <el-upload
                ref="uploadFile"
                class="upload-demo"
                action=""
                multiple
                :file-list="fileList"
                :on-exceed="handleExceed"
                :on-change="handleChange"
                :on-remove="handleRemove"
                :http-request="httpRequest"
                :before-upload="beforeUpload"
              >
                <el-button type="primary"><svg-icon icon-class="file-upload" />上传</el-button>
              </el-upload>
              <el-button
                @click="addDirsHandle"
              ><svg-icon icon-class="dir-add" />新增目录</el-button>
              <el-button
                @click="addFileHandle"
              ><svg-icon icon-class="file-add" />新建文件</el-button>
              <el-button
                @click="deleteHandle"
              ><svg-icon icon-class="file-delete" />删除</el-button>
              <el-button
                @click="downloadHandle"
              ><svg-icon icon-class="file-upload" />下载</el-button>
              <!-- <el-button
                @click="renameHandle"
              ><svg-icon icon-class="file-rename" />重命名</el-button> -->
              <!-- <el-checkbox v-model="hidden" label="显示隐藏文件" size="small" @change="isHiddenHandle"></el-checkbox> -->
            </el-button-group>
            <div>
              <el-checkbox v-model="hidden" label="隐藏文件" size="small" style="z-index:0" @change="isHiddenHandle" />
            </div>
          </div>
        </div>
        <!--  -->
        <div id="files" class="files" @contextmenu.prevent="spaceRightClick($event)">
          <el-upload
            ref="uploadFileDrag"
            class="upload-demo"
            action=""
            :disabled="authenStatus==0?true:false"
            multiple
            drag
            :http-request="httpRequest"
          >
            <el-table
              id="fileTable"
              ref="multipleTable"
              :data="filesData"
              tooltip-effect="dark"
              style="width: 100%; height: auto"
              :row-style="tableCellStyle"
              :row-class-name="tableClass"
              @cell-mouse-enter="hoverMouse"
              @row-contextmenu="rightClick"
              @row-click="rowClick"
              @row-dblclick="rowDbClick"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" width="35" />
              <el-table-column prop="name" label="名称" show-overflow-tooltip>
                <template #default="scope">
                  <el-input
                    v-if="scope.row === row && showInput === true"
                    v-model="scope.row.name"
                    size="mini"
                    style="width: 100%; hight: 100%"
                    @blur="handleBlurSubmit(scope.row)"
                    @keyup.enter="handleKeyupSubmit(scope.row)"
                  />
                  <span v-else>{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="size" label="大小" show-overflow-tooltip /> -->
              <el-table-column prop="size" label="大小" show-overflow-tooltip>
                <!-- <template #default="scope"> {{ scope.row.size }} B </template> -->
                <template #default="scope">
                  <div v-if="scope.row.size < unit">
                    {{ scope.row.size }} B
                  </div>
                  <div v-else-if="scope.row.size/unit < unit">
                    {{ (scope.row.size/unit).toFixed(2) }} KB
                  </div>
                  <div v-else-if="scope.row.size/(unit*unit) < unit">
                    {{ (scope.row.size/(unit*unit)).toFixed(2) }} MB
                  </div>
                  <div v-else-if="scope.row.size/(unit*unit*unit) < unit">
                    {{ (scope.row.size/(unit*unit*unit)).toFixed(2) }} GB
                  </div>
                  <div v-else-if="scope.row.size/(unit*unit*unit*unit) < unit">
                    {{ (scope.row.size/(unit*unit*unit*unit)).toFixed(2) }} TB
                  </div>
                  <div v-else>
                    {{ scope.row.size/(unit*unit*unit*unit*unit) }} PB
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="type" label="文件类型" show-overflow-tooltip>
                <template #default="scope">
                  <div v-if="scope.row.type === ''">
                    <svg-icon icon-class="file" />
                  </div>
                  <div v-else-if="scope.row.type === 'cmd'">
                    <svg-icon icon-class="cmd" />
                  </div>
                  <div v-else-if="scope.row.type === 'md'">
                    <svg-icon icon-class="md" />
                  </div>
                  <div v-else-if="scope.row.type === 'text'">
                    <svg-icon icon-class="txt" />
                  </div>
                  <div v-else-if="scope.row.type === 'zip'">
                    <svg-icon icon-class="zip" />
                  </div>
                  <div v-else-if="scope.row.type === 'image'">
                    <svg-icon icon-class="img" />
                  </div>
                  <div v-else><svg-icon icon-class="other" /></div>
                </template>
              </el-table-column>
              <el-table-column
                prop="modified"
                label="修改日期"
                :formatter="formatTime"
                show-overflow-tooltip
              />
            </el-table>
          </el-upload>
        </div>
      </div>
      <div v-if="isFile" ref="moveRef" class="mouse-move">
        <i class="el-icon-folder" />
      </div>
    </div>
    <!-- 右键 表格 -->
    <div v-show="menuVisible" id="contextmenu" class="fileMenu">
      <div v-show="isDir" class="contextmenu-item contextmenu-disabled">
        <svg-icon icon-class="downloadRight" />
        下载
      </div>
      <div v-show="!isDir" class="contextmenu-item" @click="downloadRight(CurrentRow)">
        <svg-icon icon-class="downloadRight" />
        下载
      </div>
      <div class="contextmenu-item" @click="addDirsRight(CurrentRow)">
        <svg-icon icon-class="add-dir" />
        新增目录
      </div>
      <div class="contextmenu-item" @click="addFileRight(CurrentRow)">
        <svg-icon icon-class="add-file" />
        新建文件
      </div>
      <div class="contextmenu-item" @click="cutRight(CurrentRow)">
        <svg-icon icon-class="cut" />
        剪切
      </div>
      <div class="contextmenu-item" @click="copyRight(CurrentRow)">
        <svg-icon icon-class="copy-file" />
        复制
      </div>
      <div class="contextmenu-item" @click="pasteRight(CurrentRow)">
        <svg-icon icon-class="paste" />
        粘贴
      </div>
      <div class="contextmenu-item" @click="deleteRight(CurrentRow)">
        <svg-icon icon-class="delete" />
        删除
      </div>
      <div class="contextmenu-item" @click="renameRight(CurrentRow)">
        <svg-icon icon-class="rename" />
        重命名
      </div>
    </div>
    <!-- 右键 左侧文件夹 -->
    <div v-show="dirsMenuVisible" id="dirsContextmenu" class="dirsFileMenu">
      <div class="contextmenu-item" @click="cutRight(currentContextMenu)">
        <svg-icon icon-class="cut" />
        剪切
      </div>
      <div class="contextmenu-item" @click="copyRight(currentContextMenu)">
        <svg-icon icon-class="copy-file" />
        复制
      </div>
      <div class="contextmenu-item" @click="pasteRight(currentContextMenu)">
        <svg-icon icon-class="paste" />
        粘贴
      </div>
      <div class="contextmenu-item" @click="deleteRight(currentContextMenu)">
        <svg-icon icon-class="delete" />
        删除
      </div>
    </div>
    <!-- 右键 空白处 -->
    <div v-show="spaceMenuVisible" id="spaceContextmenu" class="spaceFileMenu">
      <div class="contextmenu-item" @click="addDirsRight(CurrentRow)">
        <svg-icon icon-class="add-dir" />
        新增目录
      </div>
      <div class="contextmenu-item" @click="addFileRight(CurrentRow)">
        <svg-icon icon-class="add-file" />
        新建文件
      </div>
      <!-- <div class="contextmenu-item" @click="cutRight(currentContextMenu)">
        <svg-icon icon-class="cut" />
        剪切
      </div>
      <div class="contextmenu-item" @click="copyRight(currentContextMenu)">
        <svg-icon icon-class="copy-file" />
        复制
      </div> -->
      <div class="contextmenu-item" @click="pasteRight(currentContextMenu)">
        <svg-icon icon-class="paste" />
        粘贴
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessageBox, ElMessage } from 'element-plus'
// import { saveAs } from 'file-saver'
import store from '@/store'
import {
  filesList,
  addFiles,
  uploadFile,
  deleteFile,
  moveOrRenameFiles,
  copyFiles,
  addFile,
  dirsList,
  fileLinkDownload
} from '@/api/file'
import { ref } from 'vue'
import axios from 'axios'
import request from '@/utils/requestEhpc'
import { downloadProgress } from '@/mixins/downloadProgress.js'
export default {
  mixins: [downloadProgress],
  data() {
    const validateName = (rule, value, callback) => {
      const regExp = /^[A-Za-z0-9-_.]+$/
      if (value === '') {
        callback(new Error('请输入文件夹名称'))
      } else {
        if (regExp.test(value) === false) {
          callback(new Error('文件夹名称只能包括英文数字下划线横线和小数点'))
        } else {
          callback()
        }
      }
    }
    const validatePath = (rule, value, callback) => {
      const regExpPath = /^[/]+\w/
      if (this.name === 'rename') {
        if (value === '') {
          callback(new Error('请输入文件名称'))
        } else {
          callback()
        }
      } else {
        if (value === '') {
          callback(new Error('请输入文件路径'))
        } else {
          if (regExpPath.test(value) === false) {
            callback(new Error('文件路径已/开头'))
          } else {
            callback()
          }
        }
      }
    }
    return {
      // progressList: [],
      multipleSelection: [],
      currentContextMenu: '',
      fileType: 'dir',
      pasteType: '',
      isDir: true,
      authenStatus: 0,
      unit: 1024,
      upLoadProgress: 0,
      downloadIndex: 0,
      isBlur: false,
      hidden: ref(true),
      isHidden: true,
      showInput: false,
      isEnter: true,
      pasteSrc: '',
      pastePath: '',
      pasteName: '',
      pasteDir: '',
      fileRowName: '',
      directory: '',
      row: '',
      rowPath: '',
      rowName: '',
      column: '',
      addName: 'dir',
      addTitle: '新增目录',
      name: 'rename',
      title: '重命名',
      currentPath: '',
      initData: [],
      beforePath: '',
      moveCurrentPath: '',
      moveTableData: [],
      uploadConfirm: false,
      addConfirm: false,
      renameConfirm: false,
      uploadFormVisible: false,
      renameFormVisible: false,
      addFormVisible: false,
      fileData: null,
      inputType: '',
      searchInput: '',
      filesData: [],
      fileList: [],
      loading: true,
      menuVisible: false,
      dirsMenuVisible: false,
      spaceMenuVisible: false,
      path: '',
      src: '',
      fileUrl: '',
      nextPath: '',
      search: '',
      pathList: [],
      files: [],
      filesSaveList: [],
      data: [],
      defaultProps: {
        children: 'childDir',
        label: 'name'
      },
      defaultExpandedKeys: [],
      addForm: {
        path: ''
      },
      renameForm: {
        path: ''
      },
      renameRules: {
        path: [{ required: true, trigger: 'blur', validator: validatePath }]
      },
      rules: {
        path: [{ required: true, trigger: 'blur', validator: validateName }]
      },
      uploadForm: {
        file: ''
      },
      uploadFormRule: {
        // file: [{ required: true, trigger: 'change', message: '请选择文件' }]
      },
      isMove: false,
      moveList: [],
      time: null,
      timeStart: 0,
      timeEnd: 0,
      currentMove: null,
      isFile: false
    }
  },
  watch: {
    path(newValue, oldValue) {
      this.nextPath = oldValue
    }
  },
  mounted() {
    this.directory = '/public/home/' + store.getters.name
    this.path = this.directory
    // this.getDirsList()
    this.defaultExpandedKeys.push(this.directory)
    this.getFiles()
    const drag = document.getElementsByClassName('el-upload-dragger')
    drag[0].addEventListener('click', this.uploadClickEvent, false)
    drag[0].addEventListener('dragover', this.uploadDragoverEvent, false)
  },
  methods: {
    getTimeNow() {
      const now = new Date()
      return now.getTime()
    },
    // 鼠标长按
    holdDown(e) {
      this.timeStart = this.getTimeNow()
      const _this = this
      const event = e || window.event
      this.currentPath = {}
      if (event.which === 1) {
        this.time = setInterval(() => {
          _this.timeEnd = this.getTimeNow()
          if (_this.timeEnd - _this.timeStart > 500) {
            clearInterval(_this.time)
            _this.isMove = true
            _this.isFile = true
            const moveX = e.clientX
            const moveY = e.clientY
            _this.currentMove = _this.moveList[_this.moveList.length - 1]
            _this.moveList = []
            _this.$nextTick(() => {
              const move = _this.$refs.moveRef
              move.style.left = moveX - 100 + 'px'
              move.style.top = moveY - 100 + 'px'
              document.onmousemove = function(e) {
                const moveX = e.clientX
                const moveY = e.clientY
                move.style.left = moveX - 100 + 'px'
                move.style.top = moveY - 100 + 'px'
              }
            })
          }
        }, 100)
        // return
      }
    },
    // 鼠标松开
    holdUp(e) {
      clearInterval(this.time)
      this.isFile = false
      const window = document.getElementById('window')
      // 获取窗口的鼠标位置
      const m_clientX = e.clientX - window.offsetLeft
      const m_clientY = e.clientY - window.offsetTop
      // 获取树的高度
      const treeHeight = this.$refs.tree.$el.offsetHeight
      // 获取表格高度
      const tableHeight = this.$refs.multipleTable.$el.offsetHeight
      const tableWidth = this.$refs.multipleTable.$el.offsetWidth
      // 限制拖拽树的范围，如果拖拽的在这个范围之外，松开鼠标回到原位置
      if (m_clientX > 200) {
        if (m_clientY < 245 || m_clientX < 240 || m_clientY > 245 + (tableHeight - 40) || m_clientX > tableWidth + 220) {
          this.isMove = false
          this.currentMove = null
        }
      } else {
        if (m_clientY < 60 || m_clientX < 20 || m_clientY > 60 + treeHeight || m_clientX > 190) {
          this.isMove = false
          this.currentMove = null
        }
      }
      document.onmousemove = null
    },
    // 移入树高亮之后移动文件
    mouseenter({ data }) {
      this.moveList.push(data)
      if (this.isMove) {
        this.isMove = false
        const endPath = this.moveList[this.moveList.length - 1].path + '/' + this.currentMove.name
        let startPath = this.currentMove.path
        const index = startPath.lastIndexOf('/')
        if (startPath.length - 1 === index) {
          startPath = startPath.substring(0, index)
        }
        // 防止在文件在当前文件里面重复移动
        if (endPath.includes(startPath)) {
          this.isMove = false
          this.currentMove = null
          this.moveList = []
          this.isFile = false
          return
        }
        const openPath = this.moveList[this.moveList.length - 1].path
        this.path = openPath
        moveOrRenameFiles(endPath, startPath).then(response => {
          if (response.code === 200) {
            this.isMove = false
            this.currentMove = null
            this.moveList = []
            this.isFile = false
            this.getFiles()
            this.refreshTreeNode(startPath.substring(0, startPath.lastIndexOf('/')))
            this.refreshTreeNode(endPath.substring(0, endPath.lastIndexOf('/')))
            ElMessage.success('移动成功')
          } else {
            this.isMove = false
            this.currentMove = null
            this.moveList = []
            this.isFile = false
            this.getFiles()
            ElMessage.error(response.message)
          }
        }).catch(error => {
          this.isMove = false
          this.currentMove = null
          this.moveList = []
          this.isFile = false
          this.getFiles()
          ElMessage.error('移动失败', error)
        })
      }
    },
    // 移入表格高亮之后移动文件
    hoverMouse(row) {
      this.moveList.push(row)
      if (this.isMove) {
        this.isMove = false
        const movePath = this.moveList[this.moveList.length - 1]
        if (movePath.isDir) {
          if (this.currentPath.path) { return }
          if (this.currentMove.path === movePath.path) {
            return
          }
          const path = this.path + '/' + movePath.name + '/' + this.currentMove.name
          moveOrRenameFiles(path, this.currentMove.path).then((response) => {
            if (response.code === 200) {
              this.refreshTreeNode(path.substring(0, path.lastIndexOf('/')))
              this.refreshTreeNode(this.currentMove.path.substring(0, this.currentMove.path.lastIndexOf('/')))
              this.getFiles()
              this.currentMove = null
              this.moveList = []
              this.isMove = false
              ElMessage.success('移动成功')
            }
          }).catch(error => {
            this.currentMove = null
            this.moveList = []
            this.isMove = false
            ElMessage.error('移动失败', error)
          })
        } else {
          this.currentMove = null
          this.moveList = []
          this.isMove = false
        }
      }
    },
    tableClass({ row }) {
      if (!row.path) return ''
      if (this.currentMove === null) { return '' }
      if (row.path === this.currentMove.path) {
        return 'hide'
      }
      return ''
    },
    uploadClickEvent(e) {
      this.authenStatus = 0
    },
    uploadDragoverEvent(e) {
      this.authenStatus = 1
    },
    isHiddenHandle(val) {
      if (val === true) {
        this.isHidden = true
      } else {
        this.isHidden =
        false
      }
      this.$nextTick(() => {
        this.refreshTreeNode(this.path)
        this.getFiles()
      })
    },
    // 时间格式
    // 时间格式化
    formatTime(row, column) {
      const data = row[column.property]
      const dtime = new Date(data)
      const year = dtime.getFullYear()
      let month = dtime.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      let day = dtime.getDate()
      if (day < 10) {
        day = '0' + day
      }
      let hour = dtime.getHours()
      if (hour < 10) {
        hour = '0' + hour
      }
      let minute = dtime.getMinutes()
      if (minute < 10) {
        minute = '0' + minute
      }
      let second = dtime.getSeconds()
      if (second < 10) {
        second = '0' + second
      }
      return (
        year +
        '-' +
        month +
        '-' +
        day +
        ' ' +
        hour +
        ':' +
        minute +
        ':' +
        second
      )
    },
    // 左侧表格空白处 右键
    spaceRightClick(event) {
      // this.menuVisible = false
      this.dirsMenuVisible = false
      this.spaceMenuVisible = false // 先把模态框关死，目的是 第二次或者第n次右键鼠标的时候 它默认的是true
      if (this.filesData.length !== 0) {
        document.addEventListener('contextmenu', (e) => {
          const table = document.getElementById('fileTable')
          const box = document.getElementById('files')
          if (!table.contains(e.target) && box.contains(e.target)) {
            this.fileType = 'space'
            this.menuVisible = false
            this.spaceMenuVisible = true // 显示模态窗口，跳出自定义菜单栏
          }
        })
      } else {
        this.fileType = 'space'
        this.spaceMenuVisible = true // 显示模态窗口，跳出自定义菜单栏
      }
      event.preventDefault() // 关闭浏览器右键默认事件
      var menu = document.querySelector('.spaceFileMenu')
      this.spaceStyleMenu(menu, event)
    },
    spaceFoo() {
      // 取消鼠标监听事件 菜单栏
      this.spaceMenuVisible = false
      window.removeEventListener('click', this.spaceFoo) // 关掉监听，
    },
    spaceStyleMenu(menu, event) {
      // const windowTop = document.getElementsByClassName("window")[0].offsetTop;
      // const windowLeft = document.getElementsByClassName("window")[0].offsetLeft;
      var y = event.clientY
      var x = event.clientX
      // menu.style.height = '100%'
      menu.style.left = x + 'px'
      window.addEventListener('click', this.spaceFoo) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      menu.style.top = y + 'px'
    },
    // 右键
    rightClick(row, column, event) {
      this.fileType = 'file'
      this.spaceMenuVisible = false
      this.dirsMenuVisible = false
      this.menuVisible = false // 先把模态框关死，目的是 第二次或者第n次右键鼠标的时候 它默认的是true
      this.menuVisible = true // 显示模态窗口，跳出自定义菜单栏
      event.preventDefault() // 关闭浏览器右键默认事件
      this.CurrentRow = row
      this.isDir = row.isDir
      var menu = document.querySelector('.fileMenu')
      this.styleMenu(menu, event)
    },
    foo() {
      // 取消鼠标监听事件 菜单栏
      this.menuVisible = false
      window.removeEventListener('click', this.foo) // 关掉监听，
    },
    styleMenu(menu, event) {
      // const windowTop = document.getElementsByClassName("window")[0].offsetTop;
      // const windowLeft = document.getElementsByClassName("window")[0].offsetLeft;
      var y = event.clientY
      var x = event.clientX
      // menu.style.height = '100%'
      menu.style.left = x + 'px'
      window.addEventListener('click', this.foo) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      menu.style.top = y + 'px'
    },
    // 刷新
    refreshHandle() {
      this.refreshTreeNode(this.path)
      this.getFiles()
    },
    // 上一步
    prevHandle() {
      if (this.path === this.directory) {
        ElMessage({
          type: 'warning',
          message: '已在根目录下!'
        })
        return false
      }
      if (this.path.charAt(this.path.length - 1) == '/') {
        this.path = this.path.slice(0, this.path.length - 1)
      }
      const path = this.path.substring(0, this.path.lastIndexOf('/'))
      if (path === this.directory) {
        this.path = path
        this.getFiles()
      } else {
        this.path = path
        this.getFiles()
      }
    },
    // 递归列表最后一级为空
    getTreeData(data, child) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].childDir === undefined) {
          // children若为空数组，则将children设为undefined
          data[i].childDir = []
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].childDir)
        }
      }
      return data
    },
    // 数组去重
    unique(arr) {
      const res = new Map()
      return arr.filter((arr) => !res.has(arr) && res.set(arr, 1))
    },
    // loadNode 加载tree节点
    loadNode(node, resolve) {
      this.loading = true
      if (node.data.path !== undefined) {
        this.path = node.data.path
      }
      filesList(this.path, this.isHidden).then((response) => {
        this.initData = JSON.parse(JSON.stringify(response.data.items))
        this.filesData = JSON.parse(JSON.stringify(response.data.items))
        if (node.level === 0) {
          return resolve([
            {
              name: response.data.name,
              path: response.data.path,
              childDir: []
            }
          ])
        }
        // this.getTreeData(this.data, response.data.items)
        var list = []
        response.data.items.forEach((e) => {
          if (e.isDir) {
            node.data.childDir.push(e)
            response.data.items.forEach((e) => {
              if (e.isDir) {
                list.push(e)
              }
            })
          }
        })
        list = this.unique(list)
        node.childNodes = []
        if (resolve) {
          resolve(this.getTreeData(list))// 动态加载时
          this.loading = false
        } else {
          // 更新节点时：
          node.childNodes = []
          node.doCreateChildren(this.getTreeData(list))
          this.loading = false
        }
      })
    },
    // 刷新节点
    refreshTreeNode(path) {
      const node = this.$refs.tree.getNode(path)
      if (node) {
        node.loaded = false
        node.expand() // 主动调用展开节点方法，重新查询该节点下的所有子节点
      }
    },
    // 点击节点事件
    async handleNodeClick(data, node) {
      // this.loading = true
      // if (this.path === data.path) {
      //   return
      // }
      this.path = data.path
      if (this.path.substr(this.path.length - 1, 1) === '/') {
        this.path = this.path.slice(0, this.path.length - 1)
      }
      this.$nextTick(() => {
        this.getFiles()
      })
    },
    // 文件列表
    getFiles() {
      this.loading = true
      filesList(this.path, this.isHidden)
        .then((response) => {
          this.initData = JSON.parse(JSON.stringify(response.data.items))
          this.filesData = JSON.parse(JSON.stringify(response.data.items))
          // this.getTreeData(this.data, response.data.items)
          this.loading = false
        })
        .catch(() => {
          this.path = this.nextPath
          this.loading = false
        })
    },
    rowClick(row, column) {
      this.$refs.multipleTable.toggleRowSelection(row)
      this.authenStatus = 0
      this.row = row
      this.rowPath = row.path
      this.src = row.path
      this.rowName = row.name
      this.column = column
      if (this.inputType === 'dirInput' || this.inputType === 'fileInput') {
        if (this.filesData[0].name !== row.name) {
          this.showInput = false
          if (this.initData[0].name !== this.filesData[0].name) {
            // this.filesData.splice(0, 1);
            this.filesData = this.filesData.filter((files) => {
              return files.name !== ''
            })
          }
        }
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    tableCellStyle(row) {
      // if (this.row === row.row) {
      //   return 'background-color:#f5f6fa;'
      // } else {
      //   return 'background-color:#fff;'
      // }
    },
    // 下载
    async downloadHandle() {
      var ishttps = document.location.protocol == 'https:'
      var links
      if (this.multipleSelection.length === 0 || this.multipleSelection == null || this.multipleSelection == undefined) {
        ElMessage.warning('请选择需要下载的文件或者右键进行该操作')
        return
      }
      const promiseList = []
      for (const item of this.multipleSelection) {
        const promise = new Promise((resolve, reject) => {
          setTimeout(() => {
            if (item.isDir === false) {
              fileLinkDownload(item.path).then((response) => {
                var encryptUrl = response.data.encryptUrl
                const replaceStr = encryptUrl.replace(/\+/g, '%2B').replace(/=/g, '%3D').replace(/\//g, '%2F')
                if (ishttps) {
                  links = 'https://' + location.host + '/resource/file/download?path=' + replaceStr
                } else {
                  links = 'http://' + location.host + '/resource/file/download?path=' + replaceStr
                }
                const link = document.createElement('a')
                link.href = links
                link.setAttribute('download', item.name)
                document.body.appendChild(link)
                link.click()
                resolve(response)
              })
            } else {
              ElMessage.warning('文件夹不可下载！')
              resolve('文件夹不可下载！')
            }
          }, 1000)
        })
        promiseList.push(promise)
      }
      await Promise.all(promiseList).then(() => {
        this.$refs.multipleTable.clearSelection()
      })
    },
    // 右键下载
    downloadRight(row) {
      var ishttps = document.location.protocol == 'https:'
      var links
      fileLinkDownload(row.path).then((response) => {
        var encryptUrl = response.data.encryptUrl
        const replaceStr = encryptUrl.replace(/\+/g, '%2B').replace(/=/g, '%3D').replace(/\//g, '%2F')
        // var links = 'https://' + location.host + '/resource/file/download?path=' + replaceStr
        if (ishttps) {
          links = 'https://' + location.host + '/resource/file/download?path=' + replaceStr
        } else {
          links = 'http://' + location.host + '/resource/file/download?path=' + replaceStr
        }
        const link = document.createElement('a')
        link.href = links
        link.setAttribute('download', row.name)
        document.body.appendChild(link)
        link.click()
      })
    },
    // 打开新的文件夹或者下载文件
    rowDbClick(row) {
      this.authenStatus = 0
      this.row = ''
      if (row.isDir) {
        this.path = row.path
        this.getFiles()
      }
      this.$nextTick(() => {
        this.authenStatus = 1
      })
    },
    // 剪切
    cutRight(row) {
      this.pasteType = 'cut'
      this.pasteSrc = row.path
      this.pasteName = row.name
    },
    // 复制
    copyRight(row) {
      this.pasteType = 'copy'
      this.pasteSrc = row.path
      this.pasteName = row.name
    },
    // 粘贴
    async pasteRight(row) {
      if (this.pasteSrc === '') {
        ElMessage.warning('请先进行复制或者剪切文件或文件夹的操作！')
        return
      }
      if (row.isDir || this.fileType === 'dir' || this.fileType === 'space') {
        if (this.fileType === 'dir') {
          this.pastePath = row.path + '/' + this.pasteName
          this.pasteDir = row.path
        } else if (this.fileType === 'space') {
          this.pastePath = this.path + '/' + this.pasteName
          this.pasteDir = this.path
          if (this.pasteSrc.substr(this.pasteSrc.length - 1, 1) === '/') {
            this.pasteSrc = this.pasteSrc.slice(0, this.pasteSrc.length - 1)
          }
        } else {
          this.pastePath = row.path + '/' + this.pasteName
          this.pasteDir = row.path
        }
        if (this.pasteDir == this.pasteSrc) {
          ElMessage.warning('目标文件夹是源文件夹的子文件夹')
          return
        }
        if (this.pasteType === 'cut') {
          await moveOrRenameFiles(this.pastePath, this.pasteSrc).then((response) => {
            if (response.code === 200) {
              this.refreshTreeNode(this.pastePath.substring(0, this.pastePath.lastIndexOf('/')))
              this.refreshTreeNode(this.pasteSrc.substring(0, this.pasteSrc.lastIndexOf('/')))
              this.getFiles()
              ElMessage.success('粘贴成功')
            } else {
              ElMessage.error(response.message)
            }
          })
        } else {
          await copyFiles(this.pastePath, this.pasteSrc).then((response) => {
            if (response.code === 200) {
              this.refreshTreeNode(this.pastePath.substring(0, this.pastePath.lastIndexOf('/')))
              this.getFiles()
              ElMessage.success('粘贴成功')
            } else {
              ElMessage.error(response.message)
            }
          })
        }
      } else {
        ElMessage.warning('请粘贴到文件夹下')
      }
    },
    deleteHandle() {
      if (this.multipleSelection.length === 0 || this.multipleSelection == null || this.multipleSelection == undefined) {
        ElMessage.warning('请选择需要删除的文件或者右键进行该操作')
        return
      }
      ElMessageBox.confirm('确定要将选中文件删除吗？', '删除提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.batchDel()
        })
        .catch(() => {
          // ElMessage({
          //   type: "info",
          //   message: "已取消删除",
          // });
        })
    },
    async batchDel() {
      const promiseList = []
      const path = this.multipleSelection[0].path
      for (const item of this.multipleSelection) {
        const promise = new Promise((resolve) => {
          setTimeout(() => {
            deleteFile(item.path)
              .then((response) => {
                resolve(response)
              })
              .catch(() => {
                this.row = ''
              })
          }, 1000)
        })
        promiseList.push(promise)
      }
      await Promise.all(promiseList).then(() => {
        this.$notify({
          message: '删除成功',
          type: 'success'
        })
        this.row = ''
        this.refreshTreeNode(path.substring(0, path.lastIndexOf('/')))
        this.getFiles()
      })
    },
    // 删除
    deleteRight(row) {
      ElMessageBox.confirm('确定要将该文件删除吗？', '删除提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
        // type: "warning",
      })
        .then(() => {
          this.deleteFile(row.path)
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    deleteFile(path) {
      deleteFile(path)
        .then((response) => {
          this.$notify({
            message: '删除成功',
            type: 'success'
          })
          this.row = ''
          this.refreshTreeNode(path.substring(0, path.lastIndexOf('/')))
          this.getFiles()
        })
        .catch(() => {
          this.row = ''
        })
    },
    handleExceed(files, fileList) {},
    beforeUpload(file) {},
    handleChange(file, fileList) {
      const existFile = fileList.slice(0, fileList.length - 1).find(f => f.name === file.name)
      if (existFile) {
        // ElMessage.error('当前文件已经存在!')
        fileList.pop()
      }
      this.fileList = fileList
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    httpRequest(options) {
      var links
      this.filesSaveList = []
      const file = options.file
      const formdata = new FormData()
      formdata.append('file', file)
      this.fileData = formdata
      this.filesSaveList.push(formdata)
      this.filesSaveList.forEach((e) => {
        const uniSign = new Date().getTime() + ''
        var ishttps = document.location.protocol == 'https:'
        var links
        fileLinkDownload(this.path).then((response) => {
          var encryptUrl = response.data.encryptUrl
          const replaceStr = encryptUrl.replace(/\+/g, '%2B').replace(/=/g, '%3D').replace(/\//g, '%2F')
          if (ishttps) {
            links = 'https://' + location.host + '/resource/file/upload?upInfo=' + replaceStr
            axios({
              url: links,
              method: 'post',
              data: e,
              // 获取上传进度
              onUploadProgress: function(progressEvent) {
                const rate = progressEvent.loaded / progressEvent.total
                localStorage.uploadFileLength = document.getElementById('upLoadProgress').childNodes.length
                if (rate < 1) {
                  this.downLoadProgress = progressEvent.loaded / progressEvent.total * 100 | 0
                  const progress = Math.floor(this.downLoadProgress)
                  store.commit('downLoadProgress/SET_PROGRESS', { path: this.path, 'progress': progress, name: file.name, time: file.uid })
                }
              }
            })
              .then((response) => {
                if (response.data.code === 200) {
                  this.$notify({
                    message: '上传成功',
                    type: 'success'
                  })
                  store.commit('downLoadProgress/SET_PROGRESS', { path: this.path, 'progress': 100, name: file.name, time: file.uid })
                  const that = this
                  this.$nextTick(() => {
                    setTimeout(() => {
                      localStorage.uploadFileLength = document.getElementById('upLoadProgress').childNodes.length - 1
                      if (localStorage.uploadFileLength < 0) {
                        localStorage.uploadFileLength = 0
                      }
                      that.getFiles()
                    }, 2000)
                  })
                } else {
                  ElMessage.error(response.message)
                  store.commit('downLoadProgress/SET_PROGRESS', { path: this.path, 'progress': '失败', name: file.name, time: file.uid })
                }
              }).catch((err) => {
                ElMessage.error(err.message)
                store.commit('downLoadProgress/SET_PROGRESS', { path: this.path, 'progress': '失败', name: file.name, time: file.uid })
              })
          } else {
            links = 'http://' + location.host + '/resource/file/upload?upInfo=' + replaceStr
            axios({
              url: links,
              method: 'post',
              data: e,
              // 获取上传进度
              onUploadProgress: function(progressEvent) {
                const rate = progressEvent.loaded / progressEvent.total
                localStorage.uploadFileLength = document.getElementById('upLoadProgress').childNodes.length
                if (rate < 1) {
                  this.downLoadProgress = progressEvent.loaded / progressEvent.total * 100 | 0
                  const progress = Math.floor(this.downLoadProgress)
                  store.commit('downLoadProgress/SET_PROGRESS', { path: this.path, 'progress': progress, name: file.name, time: file.uid })
                }
              }
            })
              .then((response) => {
                if (response.data.code === 200) {
                  this.$notify({
                    message: '上传成功',
                    type: 'success'
                  })
                  store.commit('downLoadProgress/SET_PROGRESS', { path: this.path, 'progress': 100, name: file.name, time: file.uid })
                  const that = this
                  this.$nextTick(() => {
                    setTimeout(() => {
                      localStorage.uploadFileLength = document.getElementById('upLoadProgress').childNodes.length - 1
                      if (localStorage.uploadFileLength < 0) {
                        localStorage.uploadFileLength = 0
                      }
                      that.getFiles()
                    }, 2000)
                  })
                } else {
                  ElMessage.error(response.message)
                  store.commit('downLoadProgress/SET_PROGRESS', { path: this.path, 'progress': '失败', name: file.name, time: file.uid })
                }
              }).catch((err) => {
                ElMessage.error(err.message)
                store.commit('downLoadProgress/SET_PROGRESS', { path: this.path, 'progress': '失败', name: file.name, time: file.uid })
              })
          }
        })

        // request({
        //   url: url,
        //   method: 'post',
        //   params: {
        //     path: this.path
        //   },
        //   data: e,
        //   // 获取上传进度
        //   onUploadProgress: function(progressEvent) {
        //     const rate = progressEvent.loaded / progressEvent.total
        //     localStorage.uploadFileLength = document.getElementById('upLoadProgress').childNodes.length
        //     if (rate < 1) {
        //       this.downLoadProgress = progressEvent.loaded / progressEvent.total * 100 | 0
        //       const progress = Math.floor(this.downLoadProgress)
        //       store.commit('downLoadProgress/SET_PROGRESS', { path: this.path, 'progress': progress, name: file.name, time: file.uid })
        //     }
        //   }
        // })
        //   .then((response) => {
        //     if (response.code === 200) {
        //       this.$notify({
        //         message: '上传成功',
        //         type: 'success'
        //       })
        //       store.commit('downLoadProgress/SET_PROGRESS', { path: this.path, 'progress': 100, name: file.name, time: file.uid })
        //       const that = this
        //       this.$nextTick(() => {
        //         setTimeout(() => {
        //           localStorage.uploadFileLength = document.getElementById('upLoadProgress').childNodes.length - 1
        //           if (localStorage.uploadFileLength < 0) {
        //             localStorage.uploadFileLength = 0
        //           }
        //           that.getFiles()
        //         }, 2000)
        //       })
        //     } else {
        //       ElMessage.error(response.message)
        //       localStorage.uploadFileLength = document.getElementById('upLoadProgress').childNodes.length - 1
        //       if (localStorage.uploadFileLength < 0) {
        //         localStorage.uploadFileLength = 0
        //       }
        //     }
        //   }).catch(() => {
        //     var arr = document.getElementById('upLoadProgress').childNodes
        //     arr.forEach((e) => {
        //       if (e.className == file.uid) {
        //         document.getElementById('upLoadProgress').removeChild(e)
        //         localStorage.uploadFileLength = document.getElementById('upLoadProgress').childNodes.length - 1
        //         if (localStorage.uploadFileLength < 0) {
        //           localStorage.uploadFileLength = 0
        //         }
        //       }
        //     })
        //   })
      })
    },
    // 新增目录
    addDirsRight() {
      this.inputType = 'dirInput'
      this.row = { name: '', size: '0', type: '', modified: new Date() }
      this.filesData.unshift(this.row)
      this.showInput = true
      this.isBlur = true
    },
    addDirsHandle() {
      this.inputType = 'dirInput'
      this.row = { name: '', size: '0', type: '', modified: new Date() }
      this.filesData.unshift(this.row)
      this.showInput = true
      this.isBlur = true
    },
    // 新建文件
    addFileRight() {
      this.inputType = 'fileInput'
      this.row = { name: '', size: '0', type: 'text', modified: new Date() }
      this.filesData.unshift(this.row)
      this.showInput = true
      this.isBlur = true
    },
    addFileHandle() {
      this.inputType = 'fileInput'
      this.row = { name: '', size: '0', type: 'text', modified: new Date() }
      this.row.name.replace(/\s*/g, '')
      this.filesData.unshift(this.row)
      this.showInput = true
      this.isBlur = true
    },
    handleCloseAdd() {
      this.addFormVisible = false
      this.$refs.addForm.resetFields()
    },
    // 创建文件夹取消
    handleCancel() {
      this.addFormVisible = false
      this.$refs.addForm.resetFields()
    },
    // 树形控件 文件夹右键
    dirsRightClick(MouseEvent, object, Node, element) {
      this.fileType = 'dir'
      this.menuVisible = false
      this.spaceMenuVisible = false
      this.dirsMenuVisible = false // 先把模态框关死，目的是 第二次或者第n次右键鼠标的时候 它默认的是true
      this.dirsMenuVisible = true // 显示模态窗口，跳出自定义菜单栏
      MouseEvent.preventDefault() // 关闭浏览器右键默认事件
      this.currentContextMenu = object
      var menu = document.querySelector('.dirsFileMenu')
      this.dirsStyleMenu(menu, MouseEvent)
    },
    dirsFoo() {
      // 取消鼠标监听事件 菜单栏
      this.dirsMenuVisible = false
      window.removeEventListener('click', this.dirsFoo) // 关掉监听，
    },
    dirsStyleMenu(menu, event) {
      // const windowTop = document.getElementsByClassName("window")[0].offsetTop;
      // const windowLeft = document.getElementsByClassName("window")[0].offsetLeft;
      var y = event.clientY
      var x = event.clientX
      // menu.style.height = '100%'
      menu.style.display = 'block'
      menu.style.left = x + 'px'
      window.addEventListener('click', this.dirsFoo) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      menu.style.top = y + 'px'
    },
    // 获取当前树节点和其父级节点
    // getTreeNode(node){
    //   if (node) {
    //     if (node.label !== undefined) {
    //       this.pathList.unshift(node.label); //在数组头部添加元素
    //       this.getTreeNode(node.parent); //递归
    //       this.path=this.pathList.join('/');
    //     }
    //   }
    // },
    // 移动
    moveRight(row) {
      this.name = 'move'
      this.title = '移动至'
      this.renameFormVisible = true
      this.renameForm.path = row.path
      this.src = row.path
      this.currentPath = row.name
      this.moveTableData = this.filesData
    },
    renameHandle() {
      this.showInput = true
      this.name = 'rename'
      this.title = '重命名'
      this.inputType = 'renameInput'
      this.src = this.rowPath
    },
    // 重命名 右键
    renameRight(row) {
      this.row = row
      this.showInput = true
      this.inputType = 'renameInput'
      this.rowName = row.name
      this.src = row.path
    },
    handleBlurSubmit(row) {
      if (this.isBlur) {
        this.handleKeyupSubmit(row)
      } else {
        return
      }
    },
    handleKeyupSubmit(row) {
      this.isEnter = true
      this.isBlur = false
      row.name = row.name.replace(/\s*/g, '')
      let path = ''
      const lastStr = this.path.slice(-1)
      if (lastStr === '/') {
        path = this.path + row.name
      } else {
        path = this.path + '/' + row.name
      }
      if (this.inputType === 'renameInput') {
        if (row.name === '' || row.name === this.rowName) {
          this.showInput = false
          this.isEnter = false
          row.name = this.rowName
          return
        }
        moveOrRenameFiles(path, this.src)
          .then((response) => {
            this.inputType = ''
            this.showInput = false
            this.isEnter = false
            this.row = ''
            this.refreshTreeNode(path.substring(0, path.lastIndexOf('/')))
            this.getFiles()
          })
          .catch(() => {
            this.inputType = ''
            this.isEnter = false
            this.row = ''
            this.getFiles()
          })
      } else if (this.inputType === 'dirInput') {
        if (row.name === '') {
          this.filesData.shift(this.row)
          this.showInput = false
          this.isEnter = false
          this.filesData = this.filesData.filter((files) => {
            return files.name !== ''
          })
          return
        }
        addFiles(path)
          .then((response) => {
            if (response.code === 200) {
              this.showInput = false
              this.isEnter = false
              this.inputType = ''
              this.row = ''
              this.refreshTreeNode(path.substring(0, path.lastIndexOf('/')))
              this.getFiles()
            } else if (response.code === 455) {
              ElMessage.error(row.name + '已存在')
              this.inputType = ''
              this.isEnter = false
              this.row = ''
              this.getFiles()
            } else {
              ElMessage.error(response.message)
              this.inputType = ''
              this.isEnter = false
              this.row = ''
              this.getFiles()
            }
          })
          .catch(() => {
            this.inputType = ''
            this.isEnter = false
            this.row = ''
            this.getFiles()
          })
      } else {
        if (row.name === '') {
          this.filesData.shift(this.row)
          this.showInput = false
          this.isEnter = false
          this.filesData = this.filesData.filter((files) => {
            return files.name !== ''
          })
          return
        }
        addFile(this.path, row.name)
          .then((response) => {
            if (response.code === 200) {
              this.showInput = false
              this.isEnter = false
              this.inputType = ''
              this.row = ''
              this.getFiles()
            } else if (response.code === 455) {
              ElMessage.error(row.name + '已存在')
              this.inputType = ''
              this.row = ''
              this.isEnter = false
              this.getFiles()
            } else {
              ElMessage.error(response.message)
              this.inputType = ''
              this.row = ''
              this.isEnter = false
              this.getFiles()
            }
          })
          .catch(() => {
            this.inputType = ''
            this.row = ''
            this.isEnter = false
            this.getFiles()
          })
      }
    },
    // 重命名
    handleCloseRename() {
      this.renameFormVisible = false
      this.$refs.renameForm.resetFields()
    },
    handleCancelRename() {
      this.renameFormVisible = false
      this.$refs.renameForm.resetFields()
    },
    prevMoveHandle() {
      if (this.renameForm.path === '/') {
        ElMessage({
          type: 'warning',
          message: '已在根目录下!'
        })
        return false
      }
      const path = this.renameForm.path.substring(
        0,
        this.renameForm.path.lastIndexOf('/')
      )
      if (path === '') {
        this.renameForm.path = '/'
        this.moveTo()
      } else {
        this.renameForm.path = path
        this.moveTo()
      }
    },
    // 移动
    changeValue(moveCurrentPath) {
      this.renameForm.path = moveCurrentPath
      this.moveTo()
    },
    moveTo() {
      filesList(this.renameForm.path).then((response) => {
        this.moveTableData = response.data.items
      })
    }
  }
}
</script>

<style scoped lang="less">
.file {
  height: 100%;
  ::v-deep .el-upload-list{
    display: none;
  }
  .upload-progress{
    position: fixed;
    top: 50px;
    right: 30px;
    text-align: center;
    // background-color: #DCDCDC;
    // border-radius: 6px;
    .upload-icon{
      width: 16px;
      height: 16px;
    }
  }
  ::v-deep .el-upload-dragger{
    width: 100%;
    padding: 0 50px;
  }
  ::v-deep .el-upload-list__item .el-icon-close{
    display: none !important;
  }
  ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label{
    color: #437aec;
  }
  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner{
    background-color: #437aec;
    border-color: #437aec;
  }
  .spaceFileMenu, .dirsFileMenu, .fileMenu {
    position: fixed;
    background-color: #fff;
    width: 160px;
    padding: 0 10px;
    height: 258px;
    font-size: 12px;
    color: #313a46;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #e8eef3;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    white-space: nowrap;
    z-index: 10000;
    .svg-icon {
      width: 16px;
      height: 16px;
      vertical-align: middle;
      margin-right: 11px;
    }
    .contextmenu-disabled:hover{
      // color: #eee;
      background-color: #fff;
      cursor:not-allowed;
    }
  }
  .dirsFileMenu {
    height: 130px;
  }
  .spaceFileMenu{
    height: 98px;
  }
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    ::v-deep .el-button--primary {
      background: #437aec;
      border-color: #437aec;
    }
  }
  ::v-deep .el-upload-dragger .el-upload__text em {
    color: #437aec;
  }
  ::v-deep .el-upload-dragger:hover {
    border: 1px dashed #437aec;
  }
  .content {
    height: 100%;
    display: flex;
    font-size: 12px;
    .left {
      min-width: 220px;
      // width: 100%;
      min-height: 100%;
      overflow-y: scroll;
      box-sizing: border-box;
      font-weight: 500px;
      font-size: 14px;
      padding: 20px;
      color: #313a46;
      border-right: 1px solid #d8d8d8;
      ::v-deep .el-tree-node.is-expanded > .el-tree-node__children {
        display: inline;
      }
    }
    .right {
      padding: 20px;
      width: 80%;
      height: auto;
      color: #202020;
      .files {
        height: calc(100% - 113px);
        ::v-deep .el-table .el-table__cell{
          padding: 0;
        }
        .upload-demo{
          height: 100%;
        }
        ::v-deep .el-upload{
          height: 100%;
          width: 100%;
        }
        ::v-deep .el-upload-dragger{
          border: none;
          height: 100%;
          overflow: scroll;
          padding: 0;
        }
        ::v-deep .el-upload-dragger:hover{
          border: none;
        }
        ::v-deep .el-upload-dragger {
          background: transparent !important;
        }
        ::v-deep .el-table {
          // min-width: 590px;
          height: 100%;
        }
        ::v-deep .el-table--scrollable-x .el-table__body-wrapper {
          overflow-x: hidden;
        }
      }
      .header {
        // min-width: 590px;
        // padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #d8d8d8;
        ::v-deep .el-button:hover {
          color: #606266;
        }
        ::v-deep .el-button {
          background-color: #f0f0f0;
          padding: 10px 16px;
          border-radius: 2px;
          min-height: 34px;
          border: none;
        }
        .handle {
          padding-top: 1rem;
          ::v-deep .el-button {
            margin-right: 10px;
            background-color: #437aec;
            color: #fff;
            padding: 10px 16px;
            border-radius: 2px;
            min-height: 34px;
          }
          .svg-icon {
            width: 15px;
            height: 15px;
            vertical-align: middle;
            margin-right: 6px;
          }
        }
        ::v-deep .el-input {
          display: inline;
          margin-right: 10px;
          --el-input-focus-border: #dcdfe6;
        }
        ::v-deep .el-input__inner {
          display: inline;
          width: 50%;
          border-radius: 2px;
          height: 34px;
          line-height: 34px;
        }
        .search {
          ::v-deep .el-input__inner {
            border-radius: 2px;
            height: 34px;
            line-height: 34px;
            width: 20%;
          }
          ::v-deep .el-input__prefix {
            top: -10px;
          }
        }
      }
      .flex-style {
        display: flex;
        flex-wrap: wrap;
        min-width: 300px;
      }
      .application {
        text-align: center;
        width: 150px;
        .img-box {
          img {
            width: 88px;
            height: 88px;
            border-radius: 20px;
            box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3);
          }
        }
        .name {
          width: 100%;
          padding-top: 18px;
          margin-bottom: 47px;
          font-weight: 500;
        }
      }
    }
    .mouse-move {
      width: 200px;
      height: 200px;
      position: fixed;
      top: 0;
      left: 0;
      font-size: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: gray;
      line-height: 50px;
      z-index: 1000000;
    }
  }
}
</style>
<style>
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 12px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 6px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); */
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #d8d8d8;
}
</style>
